<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="480rem"
    class="dialog"
    top="30vh"
  >
    <div class="dialog_box flex-col">
      <span class="dialog_txt">确认设置视频封面吗？重设封面后，再次投放该视频默认使用设置后的封面</span>
      <div slot="footer" class="dialog-footer flex-row justify-center">
        <div @click="dialogVisible = false">取消</div>
        <div @click="$throttle(onOk)">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.dialogVisible = true;
    },
    // 点击确定
    onOk() {
      this.$emit("determine");
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog_box {
    .dialog_txt {
      font-size: 18rem;
      color: #252927;
      line-height: 25rem;
      text-align: center;
    }
    .dialog-footer {
      width: 100%;
      margin-top: 13%;
      div {
        width: 128rem;
        height: 42rem;
        line-height: 42rem;
        border-radius: 25rem;
        text-align: center;
        color: #2bb3e3;
        background: #ffffff;
        border-radius: 25rem;
        border: 1rem solid #32c3f6;
        cursor: pointer;
        transition: all 0.3s;
        &:nth-child(1){
          margin-right: 50rem;
        }
        &:hover {
          background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12.5% 20% 10% 20%;
}
</style>
