<template>
  <el-dialog
    title=""
    :visible.sync="payTypeStatus"
    width="557rem"
    class="dialog"
  >
    <div class="dialog_box flex-col">
      <div class="flex-row align-center">
        <div class="tips-titlt">支付金额：</div>
        <div class="tips-payAmount flex-row align-center">
          ￥<span>{{ orderData.payAmount }}</span>
        </div>
      </div>
      <div
        class="flex-row align-center"
        style="margin-top: 16rem; position: relative"
      >
        <div class="tips-titlt">支付方式：</div>
        <el-radio-group
          class="payType flex-row justify-center"
          v-model="orderData.payType"
        >
          <el-radio label="1" border :disabled="isOverstep"
            ><img src="@/assets/icon/weixin.png" alt="" /><span
              >微信支付</span
            ></el-radio
          >
          <el-radio label="4" border
            ><img src="@/assets/icon/zhifubao.png" alt="" /><span
              >支付宝</span
            ></el-radio
          >
        </el-radio-group>
        <div v-if="isOverstep" class="overstep flex-row align-center">
          <img src="@/assets/icon/pay_tanhao.png" alt="" />
          <span>超出微信支付虚拟商品日付款额度，该交易只支持支付宝付款</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flex-row justify-center">
        <div v-if="!submitStatus" id="primary-btn" @click="pay">立即支付</div>
        <div v-else id="primary-btn">
          <img src="@/assets/img/loading.gif" width="60" height="60" />
        </div>
      </div>
      <div class="service flex-row justify-center align-center">
        更多支付方式（如企业网银转账），请
        <txtServicePopover />
      </div>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */
import { payOrder, cancelOrder } from "@/api/request";
import txtServicePopover from "./txtServicePopover.vue";
import getOrderStatus from "../utils/getOrderStatus";

export default {
  components: {
    txtServicePopover,
  },
  props: {
    propsData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      payTypeStatus: false,
      submitStatus: false,
      isOverstep: false, // 支付金额是否超过5000元
      orderData: {
        orderId: "",
        payType: null,
        payAmount: 0,
      },
    };
  },
  created() {},
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    // 打开弹窗
    openPayType() {
      this.orderData = { ...this.propsData };
      if (this.orderData.payAmount > 5000) {
        this.isOverstep = true;
        this.orderData.payType = "4";
      } else {
        this.isOverstep = false;
        // 默认是微信支付
        if (this.orderData.payType == "0") {
          this.orderData.payType = "1";
        }
        // 如果是旧版的支付宝支付，则切换为支付宝当面付
        if (this.orderData.payType == "2") {
          this.orderData.payType = "4";
        }
      }
      this.payTypeStatus = true;
    },
    // 点击确定支付
    pay() {
      if (this.orderData.payType == "0") {
        this.$message({
          message: "请选择支付方式",
          type: "warning",
          duration: 3000,
          center: true,
        });
        return;
      }
      getOrderStatus(this.orderData.orderId)
        .then((status) => {
          // 如果是免审视频下单，并且当前时间超过了第一次投放时间，并且订单是待支付状态
          if (this.orderData.direct && !this.decideBeginUnix() && status == 0) {
            this.automaticCancelOrder();
            this.payTypeStatus = false;
            this.$message({
              message: "支付失败！当前时间已过第一次投放时间",
              center: true,
              type: "warning",
            });
            this.$emit("refreshOrderStatus", this.orderData.orderId); // 手动调整订单列表的订单状态为已取消
            return;
          }
          this.requestPay();
        })
        .catch(() => {
          this.requestPay();
        });
    },
    requestPay() {
      this.submitStatus = true;
      payOrder({
        order_id: this.orderData.orderId,
        pay_type: this.orderData.payType,
        is_mobile: this.isMobile,
      })
        .then(() => {
          this.submitStatus = false;
          this.payTypeStatus = false;
          // 新版支付，直接跳转至支付状态页
          this.$router.push({
            name: "PayStatus",
            query: {
              orderId: this.orderData.orderId,
            },
          });
          // // 如果选择了微信支付或者实付金额为0元，则直接跳转支付状态查询页
          // if (this.orderData.payType == "1" || this.orderData.payAmount == 0) {
          //   this.$router.push({
          //     name: "PayStatus",
          //     query: {
          //       orderId: this.orderData.orderId,
          //     },
          //   });
          //   return;
          // }
          // // 如果选择了支付宝，则直接跳转支付宝第三方支付页面
          // if (this.orderData.payType == "2") {
          //   // 加个延时器，不然跳转会被浏览器拦截
          //   setTimeout(() => {
          //     window.open(res.url, "_self");
          //   }, 100);
          // }
        })
        .catch((code) => {
          this.submitStatus = false;
          this.payTypeStatus = false;
          // 订单已经支付/超时了
          if (code == "20012" || code == "20013") {
            this.$emit("refreshPayStatus");
          }
        });
    },
    // 自动取消订单
    automaticCancelOrder() {
      cancelOrder({
        order_id: this.orderData.orderId,
      })
        .then(() => {
          console.log("自动取消订单成功");
        })
        .catch(() => {});
    },
    decideBeginUnix() {
      const targetTimestamp = this.orderData.beginUnix * 1000;
      const currentTimestamp = Date.now();
      let result = null;
      // 判断当前时间是否小于第一次投放的时间
      if (currentTimestamp < targetTimestamp) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-close {
    position: absolute;
    top: -95%;
    right: -79%;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
  }
  .dialog_box {
    .overstep {
      position: absolute;
      left: 0;
      bottom: -30rem;
      img {
        width: 20rem;
        height: 20rem;
      }
      span {
        color: #F52F3E;
        font-size: 14rem;
        margin-left: 6rem;
      }
    }
    .tips-titlt {
      height: 18rem;
      line-height: 18rem;
      border-left: 3rem solid #3bbcf9;
      padding-left: 14rem;
      margin-right: 14rem;
      font-size: 18rem;
      color: #252927;
    }
    .tips-payAmount {
      font-size: 20rem;
      color: #252927;
      line-height: 28rem;
      span {
        font-size: 28rem;
        color: #252927;
        line-height: 40rem;
        margin-left: 8rem;
      }
    }
    .dialog-footer {
      width: 100%;
      margin-top: 56rem;
      div {
        width: 158rem;
        height: 44rem;
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        border-radius: 22rem;
        text-align: center;
        line-height: 44rem;
        color: #fff;
        img {
          position: relative;
          bottom: 6rem;
        }
      }
    }
    .service {
      font-size: 16rem;
      color: #7c7c7c;
      margin-top: 24rem;
    }
  }
}
::v-deep .el-dialog__close {
  font-size: 24rem;
}
::v-deep .payType {
  height: 100%;
  .el-radio__input {
    display: none;
  }

  .icon {
    font-size: 20rem;
    margin-right: 5rem;
    position: relative;
    top: 2rem;
  }
  img {
    width: 24rem;
    margin-right: 10rem;
  }
  span {
    font-size: 16rem;
    color: #252927;
  }
}
::v-deep .el-radio.is-bordered {
  height: 40rem;
  text-align: center;
  line-height: 40rem;
  border-radius: 12rem;
  border: 1px solid #e0e0e0;
  padding: 0;
  transition: all 0.3s;
  &:hover {
    border: 1rem solid #32c3f6;
  }
}

::v-deep .el-radio.is-disabled {
  height: 40rem;
  text-align: center;
  line-height: 40rem;
  border-radius: 12rem;
  background-color: #eeeeee;
  border: 1px solid #e0e0e0;
  padding: 0;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #e0e0e0;
  }
}

::v-deep .el-radio__label {
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-radio:nth-child(1) {
  .icon {
    color: #09bb07;
  }
  .el-radio__input.is-checked + .el-radio__label {
    span {
      color: #606266;
    }
  }
}
::v-deep .el-radio:nth-child(2),
::v-deep .el-radio:nth-child(3) {
  .icon {
    color: #02a9f1;
  }
  .el-radio__input.is-checked + .el-radio__label {
    span {
      color: #606266;
    }
  }
}
::v-deep .el-radio.is-checked {
  background: linear-gradient(
    225deg,
    rgba(79, 172, 254, 0.1) 0%,
    rgba(40, 218, 227, 0.1) 100%
  );
  border: 1rem solid #32c3f6;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 60rem 70rem 32rem;
}
::v-deep .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 20rem;
}
::v-deep .el-radio {
  margin-right: 0;
}
::v-deep .el-radio {
  width: 138rem;
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
</style>
