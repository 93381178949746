<template>
  <div></div>
</template>

<script>
import { getOrder, shareOrder } from "@/api/request";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import QRCode from "qrcode";
import "../assets/font/FangZhengHeiTiJianTi-1-normal";

export default {
  props: {
    orderId: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      shareUrl: '无法获取链接地址'
    };
  },
  created() {},
  computed: {},
  methods: {
    init() {
      shareOrder({
        order_id: this.orderId,
      }).then((res) => {
        let BASE_URL = "";
        BASE_URL = process.env.VUE_APP_BASE_URL;
        const shareUrl = `${BASE_URL}/share?code=${res.shareCode}`;
        this.shareUrl = shareUrl;
        this.getMyOrder();
      }).catch(() => (
        this.getMyOrder()
      ));
    },
    getMyOrder() {
      getOrder({
        order_id: this.orderId,
        include_ad: true,
      })
        .then((res) => {
          const newArr = [];
          res.order.adList.forEach((item, index) => {
            newArr.push([
              index + 1,
              item.adId,
              moment(Number(item.beginUnix) * 1000).format(
                "YYYY-MM-DD  HH:mm:ss"
              ),
            ]);
          });
          // 创建一个新的jsPDF实例
          const doc = new JsPDF();
          doc.setFont('FangZhengHeiTiJianTi-1');
          doc.setFontSize(14); // 设置字体大小
          doc.text("“大屏时代”LED大屏广告投放订单详情", 60, 10);
          const style = {
            font: "FangZhengHeiTiJianTi-1",
          };
          doc.autoTable({
            startX: 10, // x轴
            startY: 15, // y轴
            head: [
              [
                "订单编号",
                "投放人手机号",
                "投放屏幕",
                "广告视频长度",
                "投放次数",
              ],
            ],
            body: [
              [
                res.order.orderId,
                res.order.userPhone.substring(2),
                this.$options.filters.screenFilter(res.order.name),
                `${Number(res.order.videoDuration).toFixed(0)}秒`,
                `${res.order.times}次`,
              ],
            ],
            styles: style
          });
          doc.autoTable({
            startX: 10, // x轴
            startY: 30, // y轴
            head: [
              [
                "投放间隔",
                "投放总时长",
                "开始投放时间",
                "结束投放时间"
              ],
            ],
            body: [
              [
                res.order.intervalMinute == 0 ? `不间隔，连续投放` : res.order.intervalMinute == 1440 ? `每天1次` : res.order.intervalMinute >= 60 ? `每${res.order.intervalMinute / 60}小时1次` : `每${res.order.intervalMinute}分钟1次`,
                `${res.order.adTotalTime}秒`,
                moment(Number(res.order.beginUnix) * 1000).format(
                  "YYYY-MM-DD  HH:mm:ss"
                ),
                moment(Number(res.order.endUnix) * 1000).format(
                  "YYYY-MM-DD  HH:mm:ss"
                )
              ],
            ],
            styles: style
          });
          doc.setFontSize(10); // 设置字体大小
          doc.text(`分享给好友/同事的链接：${this.shareUrl}`, 14, 58);
          // 调用qrcode库生成二维码数据URL
          QRCode.toDataURL(this.shareUrl, { errorCorrectionLevel: 'H' }, (err, url) => {
            // console.log(err, url);
            // 将二维码数据URL添加到PDF文档中
            doc.addImage(url, 'JPG', 177, 46, 20, 20);
          });
          doc.text(`远程查看大屏监控地址：https://www.daping.cn/p2pVisitor/`, 14, 76);
          QRCode.toDataURL('https://www.daping.cn/p2pVisitor/', { errorCorrectionLevel: 'H' }, (err, url) => {
            // console.log(err, url);
            doc.addImage(url, 'JPG', 177, 65, 20, 20);
          });
          doc.setFontSize(14); // 设置字体大小
          doc.text("广告投放排片时间表", 82, 96);
          doc.setFontSize(10); // 设置字体大小
          doc.text("广告的每一次投放状态（成功/失败），请点击或扫码访问上面的“分享给好友/同事的链接”", 34, 102);
          doc.setFontSize(14); // 设置字体大小
          doc.autoTable({
            startX: 10, // x轴
            startY: 108, // y轴
            head: [["编号", "广告投放ID", "开始投放时间"]],
            body: newArr,
            styles: style
          });
          this.$message({
            type: "success",
            duration: 4000,
            center: true,
            message: "PDF文件生成成功，已添加到浏览器下载",
          });
          doc.save(`${res.order.orderId}.pdf`);
          // 在新的浏览器窗口中打开PDF文档
          // doc.output('dataurlnewwindow');
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog_box {
    .dialog_txt {
      font-size: 18rem;
      color: #252927;
      line-height: 25rem;
      text-align: center;
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12.5% 20% 10% 20%;
}
</style>
