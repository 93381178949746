<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="1338rem"
      class="dialog"
      title=""
    >
      <div class="dialog_box flex-col">
        <span class="title">修改视频封面</span>
        <div class="content flex-row justify-between align-center">
          <div class="video-view justify-center">
            <video
              class="video"
              ref="videoRef"
              :src="videoUrl"
              controls
              autoplay
              muted
              loop
              crossorigin="anonymous"
            />
          </div>
          <canvas id="myCanvas" style="display: none" />
          <div class="image-view justify-center">
            <el-image
              class="image"
              fit="contain"
              :src="imgSrc ? imgSrc : videoThumbnailUrl"
              :preview-src-list="imgSrc ? [imgSrc] : [videoThumbnailUrl]"
            >
              <div
                slot="error"
                class="image-slot flex-row justify-center align-center"
              >
                <span>请截取视频封面</span>
              </div>
            </el-image>
          </div>
        </div>
        <img class="editVideoCoverTips" src="@/assets/img/editVideoCoverTips.png" alt="" />
        <div slot="footer" class="dialog-footer flex-row justify-center">
          <div class="btn1" id="default-btn" @click="cutPicture">截图</div>
          <div class="btn2" id="primary-btn" @click="onCutPicture">
            设置封面
          </div>
        </div>
      </div>
    </el-dialog>
    <determine-dialog
      @determine="determine"
      ref="refdetermineDialog"
    ></determine-dialog>
  </div>
</template>

<script>
import { getAliToken, ossUploadImg } from "../../../utils/oss";
import { updateOrderCover } from "../../../api/request";
import determineDialog from "./determineDialog.vue";

export default {
  components: {
    determineDialog,
  },
  props: {
    videoUrl: {
      type: String,
      default: "",
      required: true,
    },
    orderId: {
      type: String,
      default: "",
      required: true,
    },
    videoThumbnailUrl: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      imgSrc: "",
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.imgSrc = "";
        const ElementVideo = this.$refs.videoRef;
        if (!ElementVideo.paused) {
          ElementVideo.pause();
        }
      }
    },
  },
  created() {},
  computed: {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        const videoElement = this.$refs.videoRef;
        videoElement.load();
      });
    },
    // 截取当前帧的图片
    cutPicture() {
      const video = this.$refs.videoRef;
      const canvas = document.getElementById("myCanvas");
      video.crossOrigin = "anonymous";
      canvas.width = video.clientWidth;
      canvas.height = video.clientHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const oGrayImg = canvas.toDataURL("image/jpeg");
      this.imgSrc = oGrayImg;
    },
    // base64转blob
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // 点击设置封面
    onCutPicture() {
      if (!this.imgSrc && !this.videoThumbnailUrl) {
        this.$message({
          message: "请先截图",
          type: "warning",
          center: true,
        });
        return;
      }
      this.$refs.refdetermineDialog.openDialog();
    },
    determine() {
      // 如果没有截图过
      if (!this.imgSrc) {
        this.editOrderCover(this.videoThumbnailUrl);
        return;
      }
      // base64转blob
      const blob = this.dataURLtoBlob(this.imgSrc);
      this.beforeUpload(blob);
    },
    // 修改视频封面
    editOrderCover(url) {
      updateOrderCover({
        order_id: this.orderId,
        url
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success",
          center: true,
        });
        this.dialogVisible = false;
        this.$emit("editVideoCoverSuccess");
      });
    },
    // 上传之前获取sts 虚拟授权
    beforeUpload(file) {
      const that = this;
      return new Promise((resolve, reject) => {
        // 获取sts 的 token
        getAliToken(that, "videoPoster")
          .then((response) => {
            if (response) {
              resolve(response);
              this.handleHttpRequestImg(file);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    // 图片上传OSS
    async handleHttpRequestImg(file) {
      try {
        // 上传视频    this.dataObj为new OSS实例化的参数，上传的oss路径拼接this.uploadOssAddress
        await ossUploadImg(file, this.dataObj, this.uploadOssAddress, (res) => {
          const {
            res: { statusCode, requestUrls },
          } = res; // 返回状态和 url
          if (statusCode == 200) {
            const url = requestUrls[0];
            this.editOrderCover(url);
          }
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .image-slot {
  width: 100%;
  min-height: 250rem;
  background: #eeeeee;
}
.dialog {
  .dialog_box {
    .title{
      font-size: 22rem;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #252927;
      line-height: 30rem;
      margin-bottom: 27rem;
    }
    .video-view {
      width: 608rem;
      .video {
        max-width: 100%;
        max-height: 500rem;
      }
    }
    .image-view {
      width: 500rem;
      .image {
        max-width: 100%;
        max-height: 400rem;
      }
    }
    .editVideoCoverTips{
      width: 444rem;
      margin: 24rem 0 0 82rem;
    }
    .dialog-footer {
      width: 100%;
      margin-top: 40rem;
      .btn1 {
        width: 162rem;
        height: 46rem;
        background: #ffffff;
        border-radius: 25rem;
        border: 1px solid #32c3f6;
        text-align: center;
        line-height: 46rem;
        font-size: 18rem;
        color: #2bb3e3;
      }

      .btn2 {
        width: 162rem;
        height: 46rem;
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        border-radius: 25rem;
        text-align: center;
        line-height: 46rem;
        font-size: 18rem;
        color: #ffffff;
        margin-left: 50rem;
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 1);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 60rem 75rem 70rem;
}
</style>
