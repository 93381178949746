<template>
  <el-dialog title="" :visible.sync="reLaunchStatus" width="1280rem">
    <div class="dialog_box flex-row justify-between">
      <div class="video-view justify-center">
        <video
          ref="videoRef"
          :src="launchData.videoUrl"
          controls
          autoplay
          class="video"
          style="object-fit: contain"
        ></video>
      </div>
      <div class="launch-info flex-col">
        <span class="title-desc" v-if="launchData.designId && rejectAuditStatus"
          >您可以修改视频后再次进行投放～</span
        >
        <span class="title-desc" v-else>确定要使用这个视频再次投放吗？</span>

        <div
          v-if="launchData.designId && !rejectAuditStatus"
          class="launch-ad"
          id="primary-btn"
          @click="reLaunchOk"
        >
          直接投放同款广告
        </div>
        <div
          v-if="!launchData.designId"
          class="launch-ad"
          id="primary-btn"
          @click="reLaunchOk"
        >
          直接投放同款广告
        </div>
        <!-- <div id="primary-btn" @click="reLaunchOk">确定投放</div> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */
import canUseCanva from "@/utils/canUseCanva";

export default {
  props: {
    launchData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      reLaunchStatus: false,
      rejectAuditStatus: false, // 审核被拒或者管理员操作的结束
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    reLaunchStatus(val) {
      const videoElement = this.$refs.videoRef;
      if (!val && videoElement) {
        videoElement.pause();
      }
    },
  },
  methods: {
    // 打开弹窗
    openreLaunch() {
      this.reLaunchStatus = true;
      this.$nextTick(() => {
        const videoElement = this.$refs.videoRef;
        videoElement.load();
        // console.log(this.launchData);
        if (this.launchData.isAdminHandOver) {
          // 如果管理员操作的结束广告投放
          this.rejectAuditStatus = true;
        }
        if (
          this.launchData.status == 4
          && this.launchData.adminAuditUserId != "System"
        ) {
          // 如果订单审核被拒（第一次审核被拒的订单，不包含adminAuditUserId=='System'超时被拒的订单）
          this.rejectAuditStatus = true;
        }
      });
    },
    onClick_create() {
      // 判断能否使用Canva
      canUseCanva().then(() => {
        this.reLaunchStatus = false;
        this.$canvaUtil.onClickCreate(true);
      }).catch(() => {});
    },
    onClick_edit() {
      // 判断能否使用Canva
      canUseCanva().then(() => {
        this.reLaunchStatus = false;
        this.$canvaUtil.onClickAgainEdit(this.launchData.designId, true);
      }).catch(() => {});
    },
    // 点击确定投放按钮
    reLaunchOk() {
      // console.log(this.launchData);
      this.$router.push({
        name: "AddLaunchInfo",
        query: {
          videoUrl: this.launchData.videoUrl,
          realVideoDuration: this.launchData.videoDuration,
          times: this.launchData.times,
          intervalMinute: this.launchData.intervalMinute,
          attachOrderId: this.launchData.orderId,
          name: this.launchData.name,
          smart: this.launchData.smart,
          smartDaysTimes: this.launchData.smartDaysTimes,
          smartIntervalMinute: this.launchData.smartIntervalMinute,
          smartDays: this.launchData.smartDays,
          showTypeMap: JSON.stringify(this.launchData.showTypeMap),
        },
      });
      this.reLaunchStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_box {
  p {
    margin: 30rem 0;
    font-size: 16rem;
    color: #252927;
  }
  .video-view {
    width: 608rem;
    .video {
      max-width: 100%;
      max-height: 500rem;
    }
  }
  .launch-info {
    width: 393rem;
    // height: 386rem;
    .title-desc {
      font-size: 22rem;
      font-weight: 500;
      color: #252927;
      line-height: 30rem;
    }
    .desc {
      font-size: 18rem;
      color: #252927;
      line-height: 25rem;
      margin-top: 24rem;
      .tips {
        display: inline-block;
        color: #2bb3e3;
        border-bottom: 1rem #2bb3e3 solid;
        cursor: pointer;
      }
    }
    .launch-ad {
      width: 242rem;
      height: 46rem;
      background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
      border-radius: 25rem;
      text-align: center;
      line-height: 46rem;
      font-size: 18rem;
      font-weight: 500;
      color: #ffffff;
      margin-top: 50rem;
    }
    .edit-ad {
      width: 242rem;
      height: 46rem;
      background: #ffffff;
      border-radius: 25rem;
      border: 1rem solid #32c3f6;
      text-align: center;
      line-height: 46rem;
      font-size: 18rem;
      font-weight: 500;
      color: #2bb3e3;
      margin-top: 24rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        color: #fff;
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 92rem 116rem 94rem 60rem !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24rem;
}
</style>
