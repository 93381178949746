<template>
  <div>
    <vue-canvas-poster
      v-if="isShowPoster"
      :widthPixels="640"
      :painting="painting"
      @success="success"
      @fail="fail"
    >
    </vue-canvas-poster>
    <vue-canvas-poster
      :widthPixels="200"
      :painting="qrcodePainting"
      @success="qrcodeSuccess"
      @fail="fail"
    >
    </vue-canvas-poster>
    <el-dialog
      title=""
      :visible.sync="shareOrderStatus"
      width="720rem"
      class="dialog"
    >
      <div class="dialog_box flex-col">
        <div class="dialog_top flex-row">
          <img src="@/assets/img/share_logo.png" alt="" />
          <div class="flex-right flex-col">
            <span>分享订单</span>
            <span
              >当前生成的链接为公开链接，获得链接的任何人，均可查看广告投放信息。</span
            >
          </div>
        </div>
        <div class="share-url flex-row align-center">
          <span class="label">地址：</span>
          <el-tooltip placement="top">
            <div slot="content">{{ shareUrl.split('=')[0] }}<br>={{ shareUrl.split('=')[1] }}</div>
            <div class="url">
              <span>{{ shareUrl }}</span>
            </div>
          </el-tooltip>
          <span class="copy-txt" @click="copy">复制</span>
        </div>
        <div class="qrcode flex-row">
          <span class="label">二维码：</span>
          <div class="img-view flex-row justify-center align-center">
            <img :src="qrcodeSrc" />
          </div>
          <div class="download-view flex-col">
            <span>微信扫码分享</span>
            <span @click="downloadFile">下载二维码</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import { shareOrder } from "@/api/request";

export default {
  props: {
    shareData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
      required: true,
    },
  },
  data() {
    return {
      shareOrderStatus: false,
      isShowPoster: false, // 是否显示海报的vue-canvas-poster
      fileName: "daping.cn.png", // 生成海报的图片名称
      posterSrc: "", // 生成的海报
      qrcodeSrc: "", // 二维码路径
      shareUrl: "", // 分享地址
      painting: {
        width: "640px",
        height: "670px",
        shadow: "0px 2px 8px 0px rgba(0,0,0,0.1)",
        background: require("@/assets/img/poster_bg.png"),
        borderRadius: "8px",
        views: [
          {
            type: "image",
            url: require("@/assets/img/default_head.png"),
            css: {
              top: "28px",
              left: "40px",
              borderRadius: "10px",
              width: "50px",
              height: "50px",
            },
          },
          {
            id: "nickName",
            type: "text",
            text: "",
            css: {
              top: "39px",
              left: "106px",
              fontSize: "20px",
              color: "#252927",
            },
          },
          {
            type: "text",
            text: " 跟您分享了TA的广告投放订单",
            css: {
              top: "39px",
              left: ["116px", "nickName", 1],
              fontSize: "20px",
              color: "#8B8B93",
            },
          },
          {
            type: "text",
            text: "微信扫一扫查看订单信息",
            css: {
              top: "154px",
              left: "210px",
              fontSize: "20px",
              color: "#252927",
            },
          },
          {
            type: "qrcode",
            content: "https://www.daping.cn/",
            css: {
              top: "225px",
              left: "202px",
              color: "#000",
              background: "#fff",
              width: "236px",
              height: "236px",
            },
          },
          {
            type: "image",
            url: require("@/assets/img/logo.png"),
            css: {
              bottom: "122px",
              left: "246px",
              height: "36px",
            },
          },
          {
            type: "text",
            text: "专业LED大屏广告投放软件",
            css: {
              bottom: "80px",
              left: "206px",
              fontSize: "19px",
              color: "#929695",
            },
          },
          // {
          //   type: "text",
          //   text: "新用户1元即可体验广告投放",
          //   css: {
          //     bottom: "48px",
          //     left: "197px",
          //     fontSize: "19px",
          //     color: "#929695",
          //   },
          // },
        ],
      },
      qrcodePainting: {
        width: "80px",
        height: "80px",
        background: "",
        views: [
          {
            type: "qrcode",
            content: "https://www.daping.cn/",
            css: {
              top: "0",
              left: "0",
              color: "#000",
              background: "#fff",
              width: "80px",
              height: "80px",
            },
          },
        ],
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    shareOrder() {
      shareOrder({
        order_id: this.shareData.orderId,
      }).then((res) => {
        let BASE_URL = "";
        BASE_URL = process.env.VUE_APP_BASE_URL;
        const shareUrl = `${BASE_URL}/share?code=${res.shareCode}`;
        this.shareUrl = shareUrl;
        if (this.shareData.userImage) {
          this.painting.views[0].url = this.shareData.userImage;
        }
        this.painting.views[1].text = this.shareData.userName;
        this.painting.views[4].content = shareUrl;
        this.qrcodePainting.views[0].content = shareUrl;
        this.isShowPoster = false;
        this.shareOrderStatus = true;
      });
    },
    // 打开弹窗
    openShareOrder() {
      this.shareOrder();
    },
    success(src) {
      this.posterSrc = src;
      const a = document.createElement("a"); // 生成一个a元素
      const event = new MouseEvent("click"); // 创建一个单击事件
      a.download = this.fileName || "photo"; // 设置图片名称
      a.href = src; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    },
    qrcodeSuccess(src) {
      this.qrcodeSrc = src;
    },
    fail(err) {
      alert(err);
    },
    copy() {
      // 创建input元素
      const el = document.createElement("input");
      // 给input元素赋值需要复制的文本
      el.setAttribute("value", this.shareUrl);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand("copy");
      // 删除input元素
      document.body.removeChild(el);
      this.$message({
        type: "success",
        center: true,
        message: "复制成功",
      });
    },
    downloadFile() {
      if (this.posterSrc) {
        this.isShowPoster = false;
        const a = document.createElement("a"); // 生成一个a元素
        const event = new MouseEvent("click"); // 创建一个单击事件
        a.download = this.fileName || "photo"; // 设置图片名称
        a.href = this.posterSrc; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      } else {
        this.isShowPoster = true;
      }
      // 下载base64图片
      // const base64ToBlob = function (code) {
      //   const parts = code.split(";base64,");
      //   const contentType = parts[0].split(":")[1];
      //   const raw = window.atob(parts[1]);
      //   const rawLength = raw.length;
      //   const uInt8Array = new Uint8Array(rawLength);
      //   for (let i = 0; i < rawLength; ++i) {
      //     uInt8Array[i] = raw.charCodeAt(i);
      //   }
      //   return new Blob([uInt8Array], {
      //     type: contentType,
      //   });
      // };
      // const aLink = document.createElement("a");
      // const blob = base64ToBlob(content); // new Blob([content]);
      // const evt = document.createEvent("HTMLEvents");
      // evt.initEvent("click", true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      // aLink.download = fileName || "photo";
      // aLink.href = URL.createObjectURL(blob);
      // aLink.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-close {
    position: absolute;
    top: -135%;
    right: -142%;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
  }

  .dialog_box {
    .dialog_top {
      img {
        width: 48rem;
        height: 48rem;
      }
      .flex-right {
        margin-left: 28rem;
        margin-top: 6rem;
        span {
          &:nth-child(1) {
            font-size: 24rem;
            color: #252927;
            line-height: 33rem;
          }
          &:nth-child(2) {
            width: 483rem;
            font-size: 18rem;
            color: #7d817f;
            line-height: 29rem;
            margin-top: 13rem;
          }
        }
      }
    }
    .share-url {
      margin-top: 30rem;
      .label {
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
      }
      .url {
        width: 312rem;
        height: 44rem;
        background: #ffffff;
        border-radius: 12rem;
        border: 1px solid #e0e0e0;
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
        margin-left: 28rem;
        padding: 11rem 14rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .copy-txt {
        margin-left: 20rem;
        font-size: 16rem;
        color: #2bb3e3;
        cursor: pointer;
        &:hover {
          color: #0da2d8;
        }
      }
    }
    .qrcode {
      margin-top: 22rem;
      .label {
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
        margin-top: 11rem;
      }
      .img-view {
        width: 162rem;
        height: 162rem;
        background: #ffffff;
        border-radius: 12rem;
        border: 1px solid #e0e0e0;
        padding: 6rem;
        margin-left: 12rem;
        img {
          width: 142rem;
          height: 142rem;
        }
      }
      .download-view {
        margin: 53rem 0 0 24rem;
        span {
          &:nth-child(1) {
            font-size: 18rem;
            color: #7b7f7d;
            line-height: 29rem;
          }
          &:nth-child(2) {
            margin: 6rem 0 0 14rem;
            font-size: 16rem;
            color: #2bb3e3;
            cursor: pointer;
            &:hover {
              color: #0da2d8;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__body {
  padding: 66rem 80rem 56rem 81rem;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24rem;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>
